import React, { Component } from 'react';
import axios from 'axios';
import BackgroundPORTFOLIO from '../../images/Oilex/PORTFOLIO/PORTFOLIO1.jpg'; 
import BackgroundPORTFOLIO2 from '../../images/Oilex/PORTFOLIO/PORTFOLIO2.jpg'; 
import {translatable} from "react-multilingual";

let PORTFOLIOStyle = {
      
    backgroundImage: `url(${BackgroundPORTFOLIO2})`
  };

  let imagStyle = {
    max_width: '100%',
    height: '98px',
    object_fit: 'contain',

 
 }; 


class Portfolio extends Component {
    state = {
        portfolio: [], 
    }; 

    shouldComponentUpdate(nextProps)
    { 
      if (this.props !== nextProps) { 
               let {PortfolioApi} = nextProps;
               //console.log(PortfolioApi);
               axios.post(PortfolioApi) 
               .then(res => {
                //console.log(res)
                   this.setState({
                    portfolio: res.data.result.data
                   })
                   this.forceUpdate()
           })
        }
        return false
    }


    componentDidMount(){
        document.title = "Portfolio"
        
        let {PortfolioApi} = this.props; 
        axios.post(PortfolioApi)
        .then(res => {
            console.log(res)
            this.setState({
                 portfolio: res.data.result.data
                })
                this.forceUpdate()
        })
      }
      
  render() {
    let { aboutTitle,Portfolio1,Portfolio2 } = this.props;
    return (
      <div  > 
        
        <section className="overview-block-ptb iq-over-black-70 jarallax iq-breadcrumb3 text-left iq-font-white" style={ PORTFOLIOStyle }  >
            <div className="container" style={aboutTitle}>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-12">
                        <div className="iq-mb-0">
                            <h2 className="iq-font-white iq-tw-6">{Portfolio1}</h2>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>    

      
       
      
      
    


            
        <div class="main-content">
            <div class="overview-block-ptb iq-testimonial grey-bg">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="heading-title text-center">
                                
                                <p>{Portfolio2}</p>
                            </div>
                        </div>
                    </div>


                    
                    <div class="row iq-clients-box2 dark iq-mt-30">
                    {this.state.portfolio.map(person =>
                        <div class="col-lg-3 col-sm-6 iq-mtb-15">
                            <div class="blog brd">
                                <a  class="tooltip-container"><img alt="Oilex" class="img-fluid" 
                                style={imagStyle} src = {person.featured_image} />
                               </a>
                            </div>
                        </div>
                        ) }  
                         
                        
                         
                        
                        
                    </div>
                </div>
            </div>
            
           
             
            
             
            
            
        </div>
        
           
        
      </div>
    );
  }
}

 
const mapTranslationsToProps = ({ aboutTitle,PortfolioApi,Portfolio1,Portfolio2 }) => ({ aboutTitle,PortfolioApi,Portfolio1,Portfolio2 });

export default translatable(mapTranslationsToProps)(Portfolio);