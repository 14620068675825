import React, {Component} from "react";
import {translatable} from "react-multilingual";
import en from "./en.png";
import ar from "./ar.png";

 
class DashboardContainer extends Component {
	render() {
		let {hello,langTextAr, changeLocale} = this.props;

		return (
			<div>
				<div style={langTextAr}>
				<ul className="menu-sidebar pull-right langEnAr"  >
                                     
                                     
                                    <li className="iq">
                                     <div className="slideouticons">
                                         
                                     </div>
									
                                    </li>


									<li className="iq langEnAr2">
                                        <div className="slideouticons">
                                            <a onClick={() => changeLocale("en")}>
				                           <img  src={en} />
										  
										   </a>
										   <h6 className="langText">English</h6>
                                        </div>
                                    </li>


									<li className="iq">
                                        <div className="slideouticons">
                                            <a onClick={() => changeLocale("fa")}>
				                           <img  src={ar} />
										  
										   </a>
										   <h6 className="langText">عربى</h6>
                                        </div>
                                    </li>
                                </ul>
								</div>
				


				


				 
				 
			</div>
		);
	}
}

const mapTranslationsToProps = ({hello,ABOUT,langTextAr}) => ({hello,ABOUT,langTextAr});

export default translatable(mapTranslationsToProps)(DashboardContainer);