 
export default {
	hello : "younis",
	ABOUT : "ABOUT US",
	H : "Tags", 
	OurProducts : "Our Products", 
	News : "Our News",  
	New : "NEWS",
	enar : `http://api.oilex.co/public/api/news/list?page=true`,
	PortfolioApi : `http://api.oilex.co/public/api/portfolio/list/Portfolio?locale=en` ,
	ProdactsApi : "http://api.oilex.co/public/api/products/list/products?locale=en",
	Home : "Home",
	PRODUCTS : "PRODUCTS",
	PORTFOLIO : "PORTFOLIO",
	CONTACTS : "CONTACT US",
	home1 : "Welcome To OILEX",
	home2 : "Our Products", 
	home3 : "Our Portfolio",
	home4 : "Recent News",
	home5 : "Best Quality",
	home6 : "OILEX is a quality_oriented company that focuses on delivering highest quality products.",
	h7 : "Latest Technology",
	h8 : "We endeavor to use latest and the most sophisticated technology to make sure we produce the highest quality available and to guarantee our Employees safety.",
	h9 : "Big History",
	h10 : "OILEX is a regional Egyptian Soybean crusher with more than 23 years of commitment to the feed industry.",
	h11 : "OILEX is a regional Egyptian Soybean crusher with more than 23 years of commitment to the feed industry. OILEX plant is located in Sadat City in Egypt, mid-way between Egyptian Mediterranean ports and the Delta area, Producing consistent high-quality Soybean Meal 44% protein, Soybean Meal 48% protein and pure crude and degummed soybean oil.",
	h12 : " Soybean Oil Is a vegetable oil extracted from the seeds of the soybean. It is one of the most widely consumed cooking oils. As a drying oil, processed soybean oil is also used as a base for printing inks (soy ink) and oil paints. It is also converted to cooking oils.",
	h13 : "Useful Links",
	h14 : "Our Certificates",
	h15 : "Mail",
	h16 : "Phone",
	h17 : "Cairo",
	h18 : "50th Street, Block 46, First Industrial Zone Sadat City, Egypt",
	SliderText1 : "Premium vegetables ",
	SliderText2 : "protein products",
	about1: "About OILEX",
	about2 : "OILEX is a regional Egyptian Soybeans crusher with more than 15 years of commitment to the feed industry. OILEX plant is located in Sadat City in Egypt, mid-way between Egyptian Mediterranean ports and the Delta area, Producing consistent high-quality Soybean Meal 44% protein, Soybeans Meal 48% protein and pure crude and degummed soybean oil. OILEX is a quality-oriented company that focuses on delivering the highest quality products and logistics that covers Egypt North to the south. Although OILEX started operations during the year 2011, we rapidly held and maintained %10 of the local market with an ongoing setup and operation to increase this share to 27% by the beginning of 2013.",
	about3 : "Mission.",
	about4 : "We contribute to the industrial sector, feed industry, improving life in local communities. OILEX also values and understand that success is a direct result from our dedicated and committed to our employees. At OILEX we value our employees, provide a safe and friendly environment and make sure to let every employee work in a supportive yet challenging environment as well as developing skills and knowledge.",
	about5 : "Vision",
	about6 : "OILEX see, understand and value the role of the industrial sector on the economy. These roll and value affect our day to day decision from purchasing only high-quality raw materials, maintain a consistent quality product, continuously researching to provide the market with the latest and most effective feed products.",
	about7 : "Meet Our Team",
	Product1 : "Oilex endeavor to use latest and the most sophisticated technology to make sure we produce the highest quality available and to guarantee our Employees safety.",
	Portfolio1 : "Our Portfolio",
	Portfolio2 : "Oilex customers are distributed among the region, middle east, Africa & South Europe and varies from trader feed mill, poultry farms, fish farms, and large animals farms. We have been providing the highest quality and most efficient to our customer for the past 23 years. Our highly trained workers, engineers, and employees had granted us the number 1 company in the region.",
	New1 : "Search Here",
	New2 : "Recent News",
	CONTACT1 : "Get in Touch",
	CONTACT2 : "Name",
	CONTACT3 : "Email",
	CONTACT4 : "Phone",
	CONTACT5 : "Comment",
	CONTACT6 : "Send Message",
	CONTACT7 : "Address",
	Footer1 : "Copyright 2019",
	Footer2 : "All Rights Reserved | Designed by",
	ApplyJob : "CAREERS",
	ApplyforJob : "APPLY FOR JOB",
	UploadCV : "Upload CV",
	Choose : "Choose File",
	Submit : "Submit",
	 
	 
	  
};
 