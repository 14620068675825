import React, { Component } from 'react';
import axios from 'axios';
import Logo from '../../images/Oilex/logowhite.png';
import Background from '../../images/Oilex/Oilex4.jpg';
import BackgroundSlider1 from '../../images/Oilex/Home/Slider1.jpg';
import BackgroundSlider2 from '../../images/Oilex/Home/Slider2.jpg';
import BackgroundSlider3 from '../../images/Oilex/Home/Slider3.jpg'; 
import BackgroundWelcome from '../../images/Oilex/Home/Welcome.jpg'; 
import BackgroundFooter from '../../images/Oilex/Home/Footer.png';
import BackgroundFooter2 from '../../images/Oilex/Home/Footer2.png';
import {translatable} from "react-multilingual";
import { NavLink } from 'react-router-dom'; 
const NavBarLink = ({to, label}) => (
    <li   >
       <NavLink  target="_blank"   to={to} > {label} </NavLink>
     </li>
)

 
let imagStyle = {
    height: '234px',
    object_fit: 'cover',
 };
  

let HomeBGStyle = {
     
    backgroundImage: `url(${Background})`
  };
  
let HomeSlider1 =  {

    margin:"0px auto",
    background:"transparent",
    padding:"0px",
    margin_top:"0px",
    margin_bottom:"0px" 

}

let HomeSlider2 = {
    display:"none"
}

let HomeSlider3 = {
    z_index: "5",
    white_space: "nowrap",
    font_size: '70px',
    line_height: "90px",
    font_weight: "600",
    color: "#ffffff", 
    letter_spacing: "0px",
    font_family:"Raleway", 
    data_textAlign:"['right','right','right','right']"
}

let HomeSlider4 = {
    z_index: "6",
     box_shadow:"none",
     box_sizing:"border_box",
     _moz_box_sizing:"border_box",
     _webkit_box_sizing:"border_box",
     cursor:"pointer",
     
}

let HomeSlider5 = {
    z_index: "7",
    white_space: "nowrap",
    font_size: "16px",
    line_height: "26px",
    font_weight: "400",
    color: "#ffffff", 
    letter_spacing: "0px",
    font_family:"Open Sans",
    data_textAlign:"['left','left','left','left']"
}

let HomeSlider6 = {
    textAlign: "_webkit_right  !important",
    z_index: "8", 
    white_space: "nowrap", 
    font_size: "70px ", 
    line_height: "90px", 
    font_weight: "600", 
    color: "#ffffff", 
    letter_spacing: "0px",
    font_family:"Raleway",
     
     
}

let HomeSlider7 = {

    z_index: "10",
     background_color:"rgb(255,255,255)",
      border_radius:"4px 4px 4px 4px",
}

let HomeSlider8 = {

    z_index: "6", 
    box_shadow:"none",
    box_sizing:"border_box",
    _moz_box_sizing:"border_box",
    _webkit_box_sizing:"border_box",
    cursor:"pointer",
    
}



let HomeSlider9 ={

    z_index: "7", 
    white_space: "nowrap", 
    font_size: "16px", 
    line_height: "26px", 
    font_weight: "400", 
    color: "#ffffff", 
    letter_spacing: "0px",
    font_family:"Open  Sans",
    data_textAlign:"['center','center','center','center']"  
}

let HomeSlider10 = {

    z_index: "8", 
    white_space: "nowrap", 
    font_size: "70px", 
    line_height: "90px", 
    font_weight: "600", 
    color: "#ffffff", 
    letter_spacing: "0px",
    font_family:"Raleway",
    data_textAlign:"['right','right','right','right']"

     
}


let HomeSlider11 = {

    z_index: "9",
     background_color:"rgb(255,255,255)",
      border_radius:"4px 4px 4px 4px",
 }

 let HomeSlider12 = {

      z_index: "13",
     white_space: "nowrap",
      font_size: "24px", 
      line_height: "34px", 
      font_weight: "600", 
      color: "#ffffff", 
      letter_spacing: "0px", 
      font_family: "Raleway",
 }

 let HomeSlider13 = {

    
    color: '#ffffff', 
     
 }

 let HomeSlider14 = {
    z_index: "12", 
    background_color : "rgba(255,255,255,0.5)",
     
 }

 let HomeSlider15 = {

    visibility: "hidden !important"
  }

  let HomeSlider16 = {

    background_image: `url(${Background})`,
    background_position: "left center",
    color: '#ffffff',
     
  }


  let HomeSlider17 = {

    background_color: "#cdcdcd",
    background_position:"bottom left", 
    background_repeat:"no_repeat",
    color: '#ffffff',
}

let HomeSlider18 = {
    border:"0"
     
}

let HomeSlider1NN = {
    position:"relative",
    width:"100%",
    height:"auto",
    _top:"0px",
    margin_bottom:"0px"
     
}

let FooterCertificates = {
    width: "82%",
    height: "13%"
     
}

 
class Home extends Component {
    
    state = {
        portfolio: [], 
        News : []
    }; 
    shouldComponentUpdate(nextProps)
    { 
      if (this.props !== nextProps) { 
               let {PortfolioApi,enar} = nextProps;
               //console.log(PortfolioApi);
               axios.post(PortfolioApi)
               axios.post(enar)
               .then(res => {
                //console.log(res)
                   this.setState({
                    portfolio: res.data.result.data.slice(0,4),
                    News: res.data.result.data.slice(0,3),
                   })
                   this.forceUpdate()
           })
        }
        return false
    }
  
    async componentDidMount() {
        document.title = "Home Page"
        
        let {PortfolioApi,enar} = this.props; 

        const firstRequest = await axios.post(PortfolioApi + this.props.p1);
        const secondRequest = await axios.post('http://api.oilex.co/public/api/news/list?page=1' + this.props.p2);
        
      
        this.setState({
            portfolio: firstRequest.data.result.data.slice(0,4),
            News: secondRequest.data.result.data.slice(0,3),
             
        });
        this.forceUpdate()
      }

 

    
  render() {
    let {xx,AA,app,HUSSEIN,footerz,applogo,appFooter,slader2,slader3,aboutTitle,SliderTitle,small_title,home1,home2,SliderText1,SliderText2,home3,home4,home5,home6,h7,h8,h9,h10,h11,h12,h13,h14,h15,h16,h17,h18,ABOUT,PRODUCTS,PORTFOLIO,New,CONTACTS,ApplyJob} = this.props;
    return (
      <div  >

          
         <div id="rev_slider_117_1_wrapper" className="rev_slider_wrapper fullwidthbanner-container" data-alias="simple" data-source="gallery" style={HomeSlider1}>
             <div id="rev_slider_117_1" className="rev_slider fullwidthabanner" style={HomeSlider2} data-version="5.4.8">
                <ul>
                     <li  data-index="rs-394" data-transition="random,fadethroughdark" data-slotamount="default,default,default" data-hideafterloop="0" data-hideslideonmobile="off" data-randomtransition="on" data-easein="default,default,default" data-easeout="default,default,default" data-masterspeed="default,default,default" 
                    data-thumb={BackgroundSlider1} data-rotate="0,0,0" data-saveperformance="off" className="b1-video" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                   
                   <img src={BackgroundSlider1}  alt=""  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" className="rev-slidebg" data-no-retina />

                   
                  
                   <div  style={aboutTitle}   className="tp-caption   tp-resizeme" id="slide-394-layer-1" data-x="60" data-y="center" data-voffset="49" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":1510,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'   data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={HomeSlider3}></div>
                
                    
                   <div style={xx} className="tp-caption   tp-resizeme" id="slide-394-layer-34" data-x="60" data-y="center" data-voffset="-30" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":1510,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" >
                   <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                   
                    <br/><br/><br/><br/><br/>
                    <p  className="sliders"  >
                    <button   className="button  pull iq-mt-20"  >
                     <NavBarLink to="/PRODUCTS" label={PRODUCTS} style={HomeSlider13}  />
                     </button>
                    </p>
                     
                     
                    </div>
                     
                    
                     <div style={xx} className="tp-caption tp-resizeme  " id="slide-394-layer-34" data-x="60" data-y="center" data-voffset="-30" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":1510,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" >
                     
                     <div className="container">
                       <div className="row">
                    <h1 className="sliderheader" style={HomeSlider6}  >
                    {SliderText1}
                    
                    </h1>
                    <h1 className="sliderheader"  >
                    {SliderText2}
                    </h1>
                    </div>
                    </div>
                     
                    <br/><br/><br/><br/><br/><br/><br/><br/>
                    
                     </div>
                  </li>

                  <li data-index="rs-312" data-transition="random-static,random-premium,random" data-slotamount="default,default,default,default" data-hideafterloop="0" data-hideslideonmobile="off"  data-randomtransition="on" data-easein="default,default,default,default" data-easeout="default,default,default,default" data-masterspeed="default,default,default,default"  data-thumb="revolution/assets/100x50_81d39-bg-7.jpg"  data-rotate="0,0,0,0"  data-saveperformance="off"  class="b1-video" data-title="Slide" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                   
                     <img src={BackgroundSlider2}  alt=""  data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-no-retina />
                   
                     <div  style={aboutTitle}   className="tp-caption   tp-resizeme" id="slide-394-layer-1" data-x="60" data-y="center" data-voffset="49" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":1510,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'   data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={HomeSlider3}></div>
                
                    
                   <div style={xx} className="tp-caption   tp-resizeme" id="slide-394-layer-34" data-x="60" data-y="center" data-voffset="-30" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":1510,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" >
                   <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
                   
                    <br/><br/><br/><br/><br/>
                    <p  className="sliders"  >
                    <button   className="button  pull iq-mt-20"  >
                     <NavBarLink to="/PRODUCTS" label={PRODUCTS} style={HomeSlider13}  />
                     </button>
                    </p>
                     
                     
                    </div>
                     
                     
                     <div style={xx} className="tp-caption   tp-resizeme" id="slide-394-layer-34" data-x="60" data-y="center" data-voffset="-30" data-width="['auto']" data-height="['auto']" data-type="text" data-responsive_offset="on" data-frames='[{"delay":1510,"speed":1500,"frame":"0","from":"y:[-100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;","mask":"x:0px;y:0px;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;","ease":"Power3.easeInOut"}]'  data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" >
                    
                   
                    <h1 className="sliderheader" style={HomeSlider6}  >
                    {SliderText1}
                    
                    </h1>
                    <h1 className="sliderheader"  >
                    {SliderText2}
                    </h1>
                    
                     
                    <br/><br/><br/><br/><br/><br/><br/><br/>
                    
                     </div>
                    
                     
                     
                  </li>
                 </ul>
                <div className="tp-bannertimer tp-bottom" style={HomeSlider15} ></div>
            </div>
        </div>

         <section>
    <div className="container">
        <div className="row">
            <div className="col-lg-4 col-sm-12 iq-mt-40">
                <div className="iq-feature11 text-center iq-plr-10">
                    <div className="icon-bg text-center"><i aria-hidden="true" className="ion-ios-monitor-outline"></i>
                        <div className="step">1</div>
                    </div>
                    <h5 className="iq-tw-6 iq-mt-20 iq-mb-10">{home5}<span></span></h5>
                    <p className="iq-mall-0">{home6}</p>
                </div>
            </div>
            <div className="col-lg-4 col-sm-12 iq-mt-40">
                <div className="iq-feature11 text-center iq-plr-10 iq-re-4-mt30">
                    <div className="icon-bg text-center"><i aria-hidden="true" className="ion-ios-speedometer-outline"></i>
                        <div className="step">2</div>
                    </div>
                    <h5 className="iq-tw-6 iq-mt-20 iq-mb-10">{h7}<span></span></h5>
                    <p className="iq-mall-0">{h8}</p>
                </div>
            </div>
            <div className="col-lg-4 col-sm-12 iq-mt-40">
                <div className="iq-feature11 text-center iq-plr-10 iq-re-4-mt30">
                    <div className="icon-bg text-center"><i aria-hidden="true" className="ion-ios-list-outline"></i>
                        <div className="step">3</div>
                    </div>
                    <h5 className="iq-tw-6 iq-mt-20 iq-mb-10">{h9}<span></span></h5>
                    <p className="iq-mall-0">{h10}</p>
                    </div>
            </div>
            <section className="overview-block-pb iq-mt-0 iq-about3">
                 <div className="container" style={app}>
                     <div className="row">
                     
                         <div className="col-lg-8 col-sm-12" >
                         <div style={HUSSEIN}>
                            <h2 className="small-title iq-tw-6"  style={small_title} >{home1}
                            </h2>
                             <p>
                             <div> {h11}  </div>
                                 </p>
                             <p className="typo-style1 iq-mtb-20">
                             <h2 className="small-title iq-tw-6" style={small_title}>
                             {home2}</h2>
                             <br/>
                            {h12}
                            </p>
                            </div>
                         </div>


                         <div className="col-lg-4 col-sm-12">
                             <div className="year-bg iq-pall-15 iq-bg jarallax" style={HomeSlider16} >
                              <img className="img-fluid" src={BackgroundWelcome} alt="Oilex" />
                             </div>
                             </div>
                              
                         </div>
                     </div>
             </section>
            
        </div>
        
    </div>
    
</section>
       
<div className="main-content">             
    
              
             <section className="iq-blog overview-block-ptb iq-pb-50" style={HomeSlider17}>
                 <div className="container">
                     <div className="row" >
                         <div className="col-lg-12 col-md-12">
                             <div className="heading-title text-center">
                                 <h2 className="title iq-tw-6">{home4}</h2>
                                  
                             </div>
                         </div>
                     </div>
                     
                     <div className="row">
                     
                         <div className="col-lg-12 col-md-12">

                         {this.state.News.map(todo =>
                             <div className="col-lg-4 col-sm-12  " data-nav-dots="true" data-nav-arrow="false" data-items="3" data-xs-items="1" data-sm-items="2" data-md-items="2" data-lg-items="3" data-autoplay="true">

                            
                                 <div className="item" style={aboutTitle} key={todo.id}>
                                 
                                     <div className="iq-blog-entry white-bg">
                                         <div className="iq-entry-image clearfix">
                                      <img className="img-fluid" src={todo.img} style={imagStyle}  alt="Oilex" />
                                             
                                             <span className="date">{todo.id}</span>
                                         </div>
                                         <div className="iq-blog-detail">
                                             <div className="iq-entry-title iq-mb-10">  
                                             <h5 className="iq-tw-6">{todo.title}
                                             
                                               </h5> 
                                                 </div>
                                             <div className="iq-entry-content">
                                                 <p><div dangerouslySetInnerHTML={{__html: todo.description.slice(0,200)}} /></p>
                                             </div>
                                             
                                         </div>
                                     </div>
                                     
                                 </div>
                                   
                                    
                                  
                             </div>
                         ) }     
                            
                         </div>
                     </div>

 
                 </div>
             </section>
             
             </div>   


             <footer className="iq-footer17 black-bg overview-block-pt">
<div className="container">
    <div className="row"  style={app}>
        <div className="col-lg-4 iq-mtb-10">
            <div className="logo  ">
             <img id="footer_logo_img" className="img-fluid" src={Logo} alt="Oilex" />
               </div>
            </div>
             
             
        </div>
        
        <div className="row iq-mt-20" style={app}>
            <div className="col-lg-8 col-sm-12" style={appFooter} >
                <div className="row">
                    <div className="col-lg-6 col-sm-12 iq-mtb-20 iq-pr-0">
                        <h5 className="small-title iq-tw-6 iq-font-white iq-mb-20 iq-mtb-10">
                        {h13}</h5>
                        <div className="row" style={SliderTitle}>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <ul className="menu">
                                <div className="row">
                                            <div className="col-lg-8 col-md-6 col-sm-6">
                                                <ul className="menu">
                                                    
                                                    <NavBarLink to="/ABOUT_US"  label={ABOUT} /> 
                                                    <NavBarLink to="/PRODUCTS" label={PRODUCTS} /> 
                                                    <NavBarLink to="/PORTFOLIO" label={PORTFOLIO} />
                                                    <NavBarLink to="/News" label={New} /> 
                                                    <NavBarLink to="/CONTACTS_US" label={CONTACTS} />
                                                    <NavBarLink to="/Apply_Job" label={ApplyJob}  />

                                                </ul>
                                            </div>
                                            
                                        </div>
                                </ul>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 popular-post iq-pt-15">
                        <h5 className="small-title iq-tw-6 iq-font-white iq-mtb-10">{h14}</h5>
                        <ul className="iq-post iq-mt-20">
                            <li>
                                <div className="post-img"> <img src={BackgroundFooter} alt="Oilex" /> </div>
                                    
                            </li>
                            <li>
                                <div className="post-img" >
                                 <img style={FooterCertificates} src={BackgroundFooter2} alt="Oilex" /> </div>
                                    
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <hr className="iq-mt-20" />
                    </div>
                </div>
                <div className="row iq-contact">
                    <div className="col-sm-6 iq-mtb-10">
                        <div className="blog" style={footerz}>
                        <i aria-hidden="true" className="ion-ios-email-outline"></i>
                            <div className="content">
                                <div className="iq-tw-6 title">{h15}</div> info@oilex.co 
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 iq-mtb-10">
                        <div className="blog" style={footerz} >
                        <i aria-hidden="true" className="ion-ios-telephone-outline"></i>
                            <div className="content">
                                <div className="iq-tw-6 title">{h16}</div>
                                +20 101 510 4040
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-sm-12 dark-bg iq-plr-0 iq-mtb-10">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3442.5565214039357!2d30.5332186872811!3d30.363551205696105!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa42fee9a3934fa6b!2sOilex!5e0!3m2!1sen!2seg!4v1542552175687" height="370" style={HomeSlider18}  >
                </iframe>
                <div className="text">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                        <div className="item">
                                    <div className="iq-contact-box iq-pt-30">
                                        <div className="iq-icon">
                                            <i aria-hidden="true" className="ion-ios-location-outline"></i>
                                        </div>
                                        <div className="iq-content">
                                            <div className="iq-lead iq-font-white iq-tw-6">{h17}</div>
                                            <span className="iq-tw-6">{h18}</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     
</footer>
        
</div>  
    
   
    


    );
  }
}

  
 

 
const mapTranslationsToProps = ({ xx,AA,app,HUSSEIN,footerz,applogo,appFooter,slader2,slader3,aboutTitle,SliderTitle,small_title,home1,home2,SliderText1,SliderText2,PortfolioApi,home3,home4,home5,home6,h7,h8,h9,h10,enar,h11,h12,h13,h14,h15,h16,h17,h18,ABOUT,PRODUCTS,PORTFOLIO,New,CONTACTS,ApplyJob}) => ({ xx,AA,app,HUSSEIN,footerz,applogo,appFooter,slader2,slader3,aboutTitle,SliderTitle,small_title,home1,home2,SliderText1,SliderText2,PortfolioApi,home3,home4,home5,home6,h7,h8,h9,h10,enar,h11,h12,h13,h14,h15,h16,h17,h18,ABOUT,PRODUCTS,PORTFOLIO,New,CONTACTS,ApplyJob });

export default translatable(mapTranslationsToProps)(Home);
