import React, { Component } from 'react';
 import './App.css';


import LikeHead from './component/LikeHead';
import Navbar from './component/Navbar'; 
import Home from './component/routes/Home'; 
import About from './component/routes/About'; 
import Prodacts from './component/routes/Prodacts'; 
import Portfolio from './component/routes/Portfolio';
import PortfolioLink from './component/routes/PortfolioLink';
import News  from './component/routes/News'; 
import ApplyJob from './component/routes/ApplyJob'; 
import Jobs from './component/routes/Jobs';
  
import Contacts from './component/routes/Contacts';
import FooterOil from './component/FooterOil';
import { BrowserRouter, Route} from 'react-router-dom';
 

import {Provider} from "react-redux"; 
import {store} from "./component/routes/example/app/redux/store";


 

class App extends Component {
  render() {
    return (
    
   
      
   
      
 
      <BrowserRouter  >

        
        <div>
       
       
 
<Provider store={store}>
<div>
        <LikeHead />
       
        <Navbar />
       
			 
	 			 
			 
			
        < Route exact path="/" component={Home} />
        
        < Route path="/ABOUT_US" component={About} />
        < Route path="/PRODUCTS" component={Prodacts} />
        < Route path="/PORTFOLIO" component={Portfolio} />
        <Route   path='/News' component={News}/>
        <Route   path='/PortfolioLink' component={PortfolioLink}/>  
        <Route   path='/Apply_Job' component={ApplyJob}/> 
        <Route   path='/Jobs' component={Jobs}/>
       < Route path="/CONTACTS_US" component={Contacts} />
       
				 
        
        <FooterOil />
        </div>
        </Provider>
        </div>
      </BrowserRouter>
      
    );
  }
}

export default App;
