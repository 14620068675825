export default {
	hello : "يونس",
	ABOUT : "عن الشركة",
	H : "شعار", 
	OurProducts : "منجاتنا",
	News : "أخبارنا",
	New : "الأخبار",   
	enar : "http://api.oilex.co/public/api/news/list?locale=ar",
	PortfolioApi : `http://api.oilex.co/public/api/portfolio/list/Portfolio?locale=ar` ,
	ProdactsApi : "http://api.oilex.co/public/api/products/list/products?locale=ar",
	Home : "الصفحة الرئيسية ",
	PRODUCTS : "المنتجات",
	PORTFOLIO : "الاعمال",
	CONTACTS : "أتصل بنا ",
	home1 : "مرحبا بك في أويلكس",  
	home2 : "منتجاتنا",
	home3 : "عن اعمالنا",
	home4 : "الأخبار الحديثة",
	home5 : "أفضل جودة",
	home6 : "أويلكس هي شركة موجهة نحو الجودة تركز على تقديم منتجات بأفضل جودة",
	h7 : "أحدث التقنيات",
	h8 : "نسعى إلى استخدام أحدث التقنيات وأكثرها تطوراً للتأكد من أننا ننتج أعلى مستوى من الجودة المتاحة ونضمن سلامة موظفينا",
	h9 : "تاريخ عريق",
	h10 : " أويلكس هي كسارة فول الصويا المصرية الإقليمية مع أكثر من 23 عاما من الالتزام بصناعة الأعلاف",
	h11 : " أويلكس هي كسارة فول الصويا المصرية الإقليمية مع أكثر من 23 عاما من الالتزام بصناعة الأعلاف. يقع مصنع OILEX في مدينة السادات في مصر ، في منتصف الطريق بين موانئ البحر المتوسط المصرية ومنطقة الدلتا ، ويتم إنتاج عالي الجودة ودقيق لوجبة فول الصويا تحتوي على نسبة 44% من البروتين، وأيضًا وجبة فول الصويا التي تحتوي على نسبة 48% من البروتين وزيت فول الصويا الخام النقي منزوع الدسم.",
	h12 : "زيت الصويا هو زيت نباتي مستخرج من بذور فول الصويا. وهو واحد من أكثر زيوت الطبخ الذي يُستخدم على نطاق واسع، وكزيت للتجفيف يُستخدم زيت الصويا المُعالج كقاعدة لطباعة الأحبار (حبر الصويا) والدهانات الزيتية، كما يتم تحويله أيضًا لزيوت الطبخ.",
	h13 : "روابط مفيدة",
	h14 : "شهاداتنا",
	h15 : "البريد الالكتروني",
	h16 : "الهاتف المحمول",
	h17 : "القاهرة",
	h18 : " شارع الخمسين، بلوك 46 ، المنطقة الصناعية الأولى مدينة السادات ، مصر",
	SliderText1 : "افضل انواع ",
	SliderText2 : "الكسب",
	about1: "معلومات عن أويلكس",
	about2 : " أويلكس هي كسارة فول الصويا المصرية الإقليمية مع أكثر من 15 عاما من الالتزام بصناعة الأعلاف. يقع مصنع OILEX في مدينة السادات في مصر ، في منتصف الطريق بين موانئ البحر المتوسط المصرية ومنطقة الدلتا ، ويتم إنتاج عالي الجودة ودقيق لوجبة فول الصويا تحتوي على نسبة 44% من البروتين، وأيضًا وجبة فول الصويا التي تحتوي على نسبة 48% من البروتين وزيت فول الصويا الخام النقي منزوع الدسم. أويلكس هي شركة موجهة نحو الجودةتركز على تقديم منتجات عالية الجودة و لوجستيات من شمال مصر إلى جنوبها. على الرغم من أن أويلكس بدأت عملياتها خلال عام 2011 ، فقد حافظنا على 10٪ من السوق المحلي بسرعة وحافظنا على الإعداد والتشغيل المستمر لزيادة هذه النسبة إلى 27٪ بحلول بداية عام 2013. ",
	about3 : "مهمتنا.",
	about4 : "نحن نساهم في القطاع الصناعي ، وصناعة الأعلاف ، وتحسين الحياة في المجتمعات المحلية. كما أن أويلكس تقدر وتدرك أن النجاح هو نتيجة مباشرة من موظفينا في التفاني والالتزام. نحن في أويلكس نقدر موظفينا ، ونوفر بيئة آمنة وصديقة ، ونحرص على السماح لكل موظف بالعمل في بيئة داعمة ومليئة بالتحديات ، بالإضافة إلى تطوير المهارات والمعرفة.",
	about5 : "رؤيتنا",
	about6 : "ترى أويلكس وتفهم وتقيّم دور القطاع الصناعي على الاقتصاد، هذه القوائم والقيم تؤثر على قرارنا يومًا بعد يوم في شراء المواد الخام عالية الجودة فقط، والحفاظ على منتجات ذات جودة متسقة، والبحث باستمرار لتزويد السوق بأحدث وأكفأ منتجات الأعلاف.",
	about7 : "التق بفريقنا",
	Product1 : "أويلكس تسعى إلى استخدام أحدث التقنيات وأكثرها تطوراً للتأكد من أننا ننتج أعلى مستوى من الجودة المتاحة ونضمن سلامة موظفينا.",
	Portfolio1 : "عن الاعمال",
	Portfolio2 : "يتم توزيع عملاء أويلكس بين المنطقة ، والشرق الأوسط ، وأفريقيا وجنوب أوروبا ، و يتنوع عملاءنا من مطحنة الأعلاف التجارية ، ومزارع الدواجن ، ومزارع الأسماك ، ومزارع الحيوانات الكبيرة.	لقد قدمنا أعلى مستوى من الجودة والكفاءة لعملائنا طوال الـ 23 عامًا الماضية. بفضل عمالنا ومهندسونا وموظفينا المدربون تدريبا عاليا شركتنا رقم 1 في المنطقة.",
	New1 : "أبحث هنا",
	New2 : "الأخبار الحديثة",
	CONTACT1 : "ابقى على اتصال",
	CONTACT2 : "الاسم",
	CONTACT3 : "البريد الالكتروني", 
	CONTACT4 : "الهاتف المحمول", 
	CONTACT5 : "تعليق",
	CONTACT6 : "أرسل رسالة",
	CONTACT7 : "عنوان",
	Footer1 : "حقوق الطبع والنشر ٢٠١۹",
	Footer2 : "جميع الحقوق محفوظة ، صمم من قبل",
	ApplyJob : "وظائف",
	ApplyforJob : "وظائف",
	UploadCV : "تحميل السيرة الذاتية",
	Choose : "اختر ملف",
	Submit : "أرسل",
	 
	appFooter : {
		left: "65px",
		 },

    app : {
			direction: "rtl",
			 },

	applogo : {
	    float: "right",
		 },  

	appNav : { 
			float: "left",
			 },
    langTextAr: {
		float: "left ",
		 },

	xx: {
		left: "480px",
	    line_height: "24px",
        width: "1097px",
	 
	},
	AA: {
		left: "480px",
		 
        line_height: "24px",
         width: "1097px",
	 
	},
	HUSSEIN: {
		left: "480px",
		textAlign: "right"
	 
	},
	slader2: {
		width: "1152px",
		textAlign: "right "
	 
	},
	slader3: {
		left: "987px"
		 
	 
	},

	aboutTitle: {
		
		direction: "rtl",
		textAlign: "right ",
		 
	 
	},
	SliderTitle:{
		direction: "ltr",
	},

	footerLang: {
		 
		textAlign: "right"
	 
	},

	footerz: {
		textAlign:  "left",
		direction: "ltr"
	},
	small_title : {
		 
		display: "inline-block",
	}

};
