import React, { Component } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Switch, Route, Link , HashRouter } from "react-router-dom"; 
import BackgroundPORTFOLIO from '../../images/Oilex/PORTFOLIO/PORTFOLIO1.jpg'; 
import {translatable} from "react-multilingual";
import {NotificationContainer, NotificationManager} from 'react-notifications';
let PORTFOLIOStyle = {
      
    backgroundImage: `url(${BackgroundPORTFOLIO})`
  };
 
  

   
 
class Jobs extends Component {
  
    state = {
        name :'',
        email :'',
        phone :'',
        file :'',
    };

    handleChange = event => {
        this.setState({
            [event.target.id] : event.target.value

        })
        
    }

    handelSubmit = event => {
        event.preventDefault();
        NotificationManager.success( 'Success message',  this.state.name);

        this.setState({
            name :'',
            email :'',
            phone :'',
            file :'',
        })
        
        const Contacts = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            file: this.state.file,
            
        };
        console.log(Contacts);

        var imagefile = document.querySelector('#file');

        
        let formData = new FormData();
        formData.append('file',imagefile.files[0]);
        formData.append('name', Contacts.name);
        formData.append('email', Contacts.email);
        formData.append('phone', Contacts.phone);

        axios.post('http://api.oilex.co/public/api/careers/apply',formData,{
            headers: {
                'Accept': 'application/json'
            }
          })
           .then(res => {
            console.log("ddd");
            console.log(res);
            console.log("ddd");
            console.log(res.data);
            
            
        })
        .catch(error => {
            console.log(error.res)
        });
        
     };

    componentDidMount(){
        document.title = "jobs"
        
      }
      
  render() {
    let {ApplyJob,CONTACT2,aboutTitle,CONTACT3,CONTACT4,UploadCV,Choose,Submit} = this.props;
     return (
        <div>
        
         <section className="overview-block-ptb">
         <hr className="iq-mtb-10" />
             <div className="container" style={aboutTitle}>
                 <div className="row">
                     <div className="col-lg-12 col-md-12">
                         <div className="heading-title text-center">
                         
                             <h2 className="title iq-tw-6">{ApplyJob}</h2>
                         </div>
                     </div>
                 </div>
                 <div className="row justify-content-md-center">
                     <div className="col-md-8">
                         <div className="iq-login iq-brd iq-pt-40 iq-pb-30 iq-plr-30">
                             <form onSubmit={this.handelSubmit}>

                                     



                                 <div className="form-group">
                                     <label className="iq-font-black" >
                                     {CONTACT2}</label>
                                     <input type="text" className="form-control" id="name" name="name"  placeholder={CONTACT2} onChange={this.handleChange} value={this.state.name} required />
                                 </div>


                                 <div className="form-group iq-mt-20">
                                     <label className="iq-font-black" >{CONTACT3}</label>
                                     <input type="email" className="form-control" id="email" name="email"  placeholder={CONTACT3} onChange={this.handleChange} required value={this.state.email} />
                                 </div>

                                 <div className="form-group iq-mt-20">
                                     <label className="iq-font-black" >{CONTACT4}  </label>
                                     <input type="number" className="form-control" id="phone" name="phone"  placeholder={CONTACT4} onChange={this.handleChange} required value={this.state.phone} />
                                 </div>

                                 
                                 
                                 <div className="form-group iq-mt-20">
                                 <div className="inputfile-box">
                                 <label className="iq-font-black" >{UploadCV}</label>
                                 <span id="file-name" className="file-box"> 
                                 <input type="file" id="file" name="file" className="inputfile"  onChange={this.handleChange} required value={this.state.file} />
                                 <label for="file">
                                    <span className="file-button">
                                    {Choose}
                                     </span>
                                 </label>
                                 </span>
                                 </div>
                                 </div>
                                  





                                 <button type="submit" onClick={this.handelSubmit.this} name="submit" type="submit" value="Send" className="button iq-mt-40">{Submit}</button>
                                 <p role="alert"></p>
                                        <NotificationContainer/> 
                             </form>
                            
                             
                         </div>
                         
                     </div>
                 </div>
             </div>
         </section>
      
     
   </div>
    ); 
  }
  
} 

class Player extends Component {
    render() {
   
    return (
        <Roster/>
     );
    }
    
  } 
  const Roster = () => (
    <Switch>
      
      <Route exact path='/Jobs' component={Player}/>
    </Switch>
  )
  

  const mapTranslationsToProps = ({ApplyJob,CONTACT2,aboutTitle,CONTACT3,CONTACT4,UploadCV,Choose,Submit}) => ({ApplyJob,CONTACT2,aboutTitle,CONTACT3,CONTACT4,UploadCV,Choose,Submit});

export default translatable(mapTranslationsToProps)(Jobs);
 

 
