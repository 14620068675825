import React, { Component } from 'react';
import Background from '../../images/Oilex/ABOUT_US/ABOUT1.jpg';
import Roster from './Jobs';
import axios from 'axios';
//import './pagination2';
import { BrowserRouter as Router, Switch, Route, Link , HashRouter } from "react-router-dom"; 
import {translatable} from "react-multilingual";

let ABOUTStyle = {
      
    backgroundImage: `url(${Background})`,
    background_size: "cover !important",
  };

 
 
 

class ApplyJob extends Component {
    state = {
        Job: [], 
    };
     shouldComponentUpdate(nextProps)
    { 
      if (this.props !== nextProps) { 
               let {ProdactsApi} = nextProps;
               //console.log(ProdactsApi);
               axios.post(ProdactsApi) 
               .then(res => {
                //console.log(res)
                   this.setState({
                    Job: res.data.result
                   })
                   this.forceUpdate()
           })
        }
        return false 
    }

    componentDidMount(){
        document.title = "Apply Job"
         
        //let {ProdactsApi} = this.props; 
        axios.post('http://api.oilex.co/public/api/careers/list')
        .then(res => {
            console.log(res)
            this.setState({
                Job: res.data.result
                })
                this.forceUpdate()
        })


      }
  render() {
    let {ApplyforJob,aboutTitle } = this.props;
    return (
      <div  >
        
        <section className="overview-block-ptb iq-over-black-70 jarallax iq-breadcrumb3 text-left iq-font-white" style={ ABOUTStyle }  >
            <div className="container" style={aboutTitle}>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-12">
                        <div className="iq-mb-0">
                            <h2 className="iq-font-white iq-tw-6">
                            <p>
					{ApplyforJob}
				</p>
                </h2>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>     



 
         

        <section class="iq-clients overview-block-ptb grey-bg">
                <div class="container" style={aboutTitle}>
                     
                    <div class="row">
                    {this.state.Job.map(Jobs =>
                        <div class="col-lg-6 col-sm-12" key={Jobs.id}>
                            <div class="iq-clients-blog iq-mtb-15">
                                <div class="media iq-pall-20 align-items-center">
                                    <div class="media-left">
                                   
                                    <Link to={`/Jobs`}>
                                    <button id="submit"   name="submit" type="submit" value="Send" className="button pull-right iq-mt-20"> Apply Now</button>
                                    </Link>
                                    </div>
                                    <div class="media-body">
                                        <h4 class="media-heading mt-0 iq-tw-6">{Jobs.position}</h4>
                                        <p> 
                                        <div dangerouslySetInnerHTML={{__html: Jobs.description.slice(0,200)}}/>
                                       
                                        .</p> 
                                        
                                    </div>
                                </div>
                              </div>
                     

                        </div>
                      ) }      
                        
                    </div>
                </div>
            </section>



        
      </div>
    );
  }
}

const mapTranslationsToProps = ({ApplyforJob,aboutTitle }) => ({ApplyforJob,aboutTitle });

export default translatable(mapTranslationsToProps)(ApplyJob);
 
