import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import {translatable} from "react-multilingual";
import { BrowserRouter as Router, Switch, Route, Link , HashRouter } from "react-router-dom"; 
import Background from '../../images/Oilex/Oilex4.jpg';
//import PortfolioLink from './PortfolioLink';
 


let paginationStyle = {
     display: "-webkit-inline-box"
  };
  
  let imagStyle = {
    height: '234px',
    object_fit: 'cover',
 };

 const PER_PAGE = 50 
 

class TodoApp extends React.Component {
    
     

     
   
    

    constructor() {
        
      super();
      
      this.state = {
        todos: [],
        Recent:[],
        Tags:[],
        search:'',
         
        offset: 0,
        limit: PER_PAGE,
        currentPage: '',
        todosPerPage: 1,
        upperPageBound: 1,
        lowerPageBound: 0,
        isPrevBtnActive: '',
        isNextBtnActive: '',
        pageBound: 3
      };
      this.handlePageClickNext = this.handlePageClickNext.bind(this)
      this.handlePageClickPrev = this.handlePageClickPrev.bind(this)

      this.handleClick = this.handleClick.bind(this);
      this.btnDecrementClick = this.btnDecrementClick.bind(this);
      this.btnIncrementClick = this.btnIncrementClick.bind(this);
      this.btnNextClick = this.btnNextClick.bind(this);
      this.btnPrevClick = this.btnPrevClick.bind(this);
       this.componentDidMount = this.componentDidMount.bind(this);
      this.setPrevAndNextBtnClass = this.setPrevAndNextBtnClass.bind(this);
    }

    updateSearch(event) {
        this.setState({search: event.target.value.substr(0,100)});
    }

    componentDidUpdate() {
        $("ul li.active").removeClass('active');
        $('ul li#'+this.state.currentPage).addClass('active');
  }
  handleClick(event) {
    let listid = Number(event.target.id);
    this.setState({
      currentPage: listid
    });
    $("ul li.active").removeClass('active');
    $('ul li#'+listid).addClass('active');
    this.setPrevAndNextBtnClass(listid);
  }
  setPrevAndNextBtnClass(listid) {
    let totalPage = Math.ceil(this.state.todos.length / this.state.todosPerPage);
    this.setState({handlePageClickNext: 'disabled'});
    this.setState({handlePageClickPrev: 'disabled'});
    if(totalPage === listid && totalPage > 1){
        this.setState({handlePageClickPrev: ''});
    }
    else if(listid === 1 && totalPage > 1){
        this.setState({handlePageClickNext: ''});
    }
    else if(totalPage > 1){
        this.setState({handlePageClickNext: ''});
        this.setState({handlePageClickPrev: ''});
    }
}
  btnIncrementClick() {
      this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
      this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
      let listid = this.state.upperPageBound + 1;
      this.setState({ currentPage: listid});
      this.setPrevAndNextBtnClass(listid);
}
  btnDecrementClick() {
    this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
    this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
    let listid = this.state.upperPageBound - this.state.pageBound;
    this.setState({ currentPage: listid});
    this.setPrevAndNextBtnClass(listid);
}
btnPrevClick() {
    if((this.state.currentPage -1)%this.state.pageBound === 0 ){
        this.setState({upperPageBound: this.state.upperPageBound - this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound - this.state.pageBound});
    }
    let listid = this.state.currentPage - 1;
    this.setState({ currentPage : listid});
    this.setPrevAndNextBtnClass(listid);
}
btnNextClick() {
    if((this.state.currentPage +1) > this.state.upperPageBound ){
        this.setState({upperPageBound: this.state.upperPageBound + this.state.pageBound});
        this.setState({lowerPageBound: this.state.lowerPageBound + this.state.pageBound});
    }
    let listid = this.state.currentPage + 1;
    this.setState({ currentPage : listid});
    this.setPrevAndNextBtnClass(listid);
}


    
   
  loadUsersFromServer() {
    fetch(`http://api.oilex.co/public/api/news/list/?limit=${this.state.limit}&offset=${this.state.offset}`)
       .then(response => {
      this.setState({ todos: response.data.result.data, })
    })
  }
  handlePageClickPrev() {
    axios.post('http://api.oilex.co/public/api/news/list?json=1') 
              
      .then(res => {
          console.log(res)
     
          this.setState({
            todos: res.data.result.data,
            currentPage : res.data.result.current_page, 
               
              })
              
               
      })
    let offset = this.state.offset - PER_PAGE
    if (offset < 0) return 
    this.setState({offset: offset}, () => {
      this.loadUsersFromServer();
    });
  };
  handlePageClickNext() {
    axios.post('http://api.oilex.co/public/api/news/list?page=2') 
              
      .then(res => {
          console.log(res)
     
          this.setState({
            todos: res.data.result.data,
            currentPage : res.data.result.current_page, 
               
              })
              
               
      })
    let offset = this.state.offset + PER_PAGE
    if (offset >= this.state.total) return

    this.setState({offset: offset}, () => {
      this.loadUsersFromServer();
    });
  };
  shouldComponentUpdate(nextProps, nextState)
    { 
        
       
        if (this.props !== nextProps) { 
               let {enar} = nextProps;
               console.log(enar);
 
               axios.post(enar) 
                   
            .then(res => {
       //console.log(res)
          
               this.setState({
                   todos: res.data.result.data,
                   Recent: res.data.result.data.slice(0,2),
                   Tags : res.data.result.data[0].tags,
                   currentPage : res.data.result.current_page, 
                   
                    
                   })
                   this.forceUpdate()
           })
           .catch(error => {
            console.log(error)
        });
        }
         
       
 
        return (this, nextProps, nextState);

    }
     
  componentDidMount(){
  let {enar} = this.props; 
    axios.post(enar) 
            
    .then(res => {
        console.log(res)
   
        this.setState({
            todos: res.data.result.data,
            Recent: res.data.result.data.slice(0,2),
            Tags : res.data.result.data[0].tags,
            currentPage : res.data.result.current_page, 

 
            })
            
            this.forceUpdate()
    }).catch(error => {
        console.log(error)
    });
    
     
    
  }
    render() {
        function formatDate(string){
            var options = { month: 'long',  day: 'numeric', year: 'numeric' };
            return new Date(string).toLocaleDateString([],options);
        }
        


       


     let {H,aboutTitle,small_title,New1,New2} = this.props;   
                  
     const { todos, currentPage, todosPerPage,upperPageBound,lowerPageBound,isPrevBtnActive,isNextBtnActive } = this.state;
     // Logic for displaying current todos
     const indexOfLastTodo = currentPage * todosPerPage;
     const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
     const currentTodos = todos.slice(indexOfFirstTodo, indexOfLastTodo);
     
      let filteredPerson = this.state.todos.filter(
        (todo) => {
            return todo.title.indexOf(this.state.search) !== -1;
            
        }
    );
    const todosdd = currentTodos.map(( )=> {
         // console.log(currentTodos)
      //console.log("h4")
        return (

    <div>
        
       
        
      
         
     
  <br/><br/><br/> 
          
      {filteredPerson.map((todo, index) =>
      
      
       
      <div className="col-lg-6 col-sm-12" key={index}>
                                  <div className="iq-blog-entry white-bg">
                                      <div className="iq-entry-image clearfix">
                                          <img className="img-fluid" src={todo.img} style={imagStyle}  alt="Oilex" />
                                           
                                          <span className="date">{todo.id}<small> </small></span>
                                      </div>
                                      <div className="iq-blog-detail">
                                          <div className="iq-entry-title iq-mb-10">
                                              
                                              
                                                      <h5 className="iq-tw-6">
                                                     
                                                      {todo.title}</h5>
                                               
                                          </div>
                                          <div className="iq-entry-content">
                                           
                                          <p><div dangerouslySetInnerHTML={{__html: todo.description }} /></p>
                                          </div>
                                          
                                      </div>
                                  </div>  
                              </div>
                               
     ) }
    
                
                
                              
                              
    </div>
           
    );
               

                 
      });

      // Logic for displaying page numbers
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(todos.length / todosPerPage); i++) {
        pageNumbers.push(i);
      }

      const renderPageNumbers = pageNumbers.map(number => {
          if(number === 1 && currentPage === 1){
              return(
                  <li key={number} className='active' id={number}><a   id={number} onClick={this.handlePageClickPrev}>{number}</a></li>
              )
          }
          else if((number < upperPageBound + 1) && number > lowerPageBound){
              return(
                  <li key={number} id={number}><a   id={number} onClick={this.handlePageClickNext}>{number}</a></li>
              )
          }
      });

      
      let pageIncrementBtn = null;
      if(pageNumbers.length > upperPageBound){
          pageIncrementBtn = <li className=''><a   onClick={this.btnIncrementClick}> &hellip; </a></li>
      }
      let pageDecrementBtn = null;
      if(lowerPageBound >= 1){
          pageDecrementBtn = <li className=''><a   onClick={this.btnDecrementClick}> &hellip; </a></li>
      }

     // <button onClick={this.handlePageClickPrev}>Prev</button>
      let handlePageClickPrev = null;
      if(isPrevBtnActive === 'disabled') {
          handlePageClickPrev = <li className={isPrevBtnActive}><span id="btnPrev"> &laquo; </span></li>
      }
      else{
          handlePageClickPrev = <li className={isPrevBtnActive}><a   id="btnPrev" onClick={this.handlePageClickPrev}>  &laquo; </a></li>
      }




      //<button onClick={this.handlePageClickNext}>Next</button>
      let handlePageClickNext = null;
      if(isNextBtnActive === 'disabled') {
          handlePageClickNext = <li className={isNextBtnActive}><span id="btnNext"> &raquo; </span></li>
      }
      else{
          handlePageClickNext = <li className={isNextBtnActive}><a   id="btnNext" onClick={this.handlePageClickNext}> &raquo; </a></li>
      }

      
      return (
        <div>                

              

              <div className="main-content">
          
          <section className="iq-blog overview-block-ptb">
              <div className="container" style={aboutTitle} >
                  <div className="row">


                            <div className="col-lg-4 col-md-12 col-sm-12 iq-mtb-15">
                            <div className="iq-post-sidebar"> 
                            
                              <div className="iq-sidebar-widget">
                                    <h5 className="iq-tw-6 small-title iq-font-dark" style={small_title}
                                    > {New1} </h5>
                                    <div className="iq-widget-search"> <i className="fa fa-search"></i>
                                        <input type="search"
                                        value={this.state.search}
                                        onChange={this.updateSearch.bind(this)}
                                        placeholder="Search...." className="form-control placeholder" />
                                    </div>
                                </div>
                              
                              <div className="iq-sidebar-widget">
                                    <h5 className="iq-tw-6 small-title iq-font-dark" style={small_title}>{New2}</h5>
                                {this.state.Recent.map(todo =>
                                    <div className="iq-recent-post media" key={todo.id}>
                                        <div className="media-left iq-mr-10"> 
                                        
                                            <img alt="Oilex" className="media-object RecentNews" src={todo.img} />  </div>
                                        <div className="media-body">
                                          {todo.title.slice(0,30)} 
                                         <br/>
                                         <span  >
                                             <i className="fa fa-calendar"></i>
                                             {formatDate(todo.updated_at)} <br/>
                                            
                                               </span> 
                                        </div>
                                    </div>
                                    ) }
     
                                </div>
                            

                               

                             
                              <div className="iq-sidebar-widget">
                                    <h5 className="iq-tw-6 small-title iq-font-dark" style={small_title}
                                    >{H}</h5>
                                    {this.state.Tags.map(todo =>
                                    <ul className="iq-tag list-inline" key={todo.id}>
                                         
                                         <li> {todo.name} </li>
                                         
                                         
                                         
                                    </ul>
                                     ) }
                                </div>

                                 
                                 </div>
                        </div>

                        <div className="col-lg-8 col-md-12 iq-mtb-20"> 
                        
                        <div className="col-lg-12 col-sm-12" >
                        {todosdd}
                         </div>

                          <ul className="pagination" style={paginationStyle} > 

{handlePageClickPrev} 
{renderPageNumbers}
{handlePageClickNext}

</ul>

       
                        </div>         
                               


            <div className="col-lg-8 col-md-12 iq-mtb-20"> 
            
                        <div className="col-lg-12 col-sm-12" >
                        <ul>
         
       </ul>
       
                         </div>

                          <ul className="pagination" style={paginationStyle} > 

 

</ul>
                        </div>
           
           


           
             </div>
                               
                            </div>
                            
            </section>
           
                 
        </div>
           
          
         
        </div>
      );
    }
  }
  const mapTranslationsToProps = ({enar, H,aboutTitle,small_title,New1,New2}) => ({enar, H,aboutTitle,small_title,New1,New2});

  export default translatable(mapTranslationsToProps)(TodoApp);
   