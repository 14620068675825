import React, { Component } from 'react';
import axios from 'axios';
// import './pagination2';
 import { BrowserRouter as Router, Switch, Route, Link , HashRouter } from "react-router-dom"; 
 import BackgroundPORTFOLIO from '../../images/Oilex/PORTFOLIO/PORTFOLIO1.jpg'; 
//import BackgroundPORTFOLIO3 from '../../images/Oilex/PORTFOLIO/PORTFOLIO3.jpg'; 
//import Roster from './News';
let PORTFOLIOStyle = {
      
    backgroundImage: `url(${BackgroundPORTFOLIO})`
  };
 
  

   
 
class PortfolioLink extends Component {
  state = {
    players: [], 
}; 
 
  
  render() {
    
     return (
      <div  >
       <Roster/>
       
               
     </div>
    );
  }
  
} 

const players = {

  componentDidMount(){
    axios.post('http://api.oilex.co/public/api/news/list')
    .then(res => {
        console.log(';;')
        console.log(res)
        this.setState({
          players: res.data.result.data
         })
      
         
           
            this.forceUpdate()
    })
  }
  
}
 
const PlayerAPI = {
  players: [
    { id: 1, title: "Ben Blocker", position: "G" },
    { id: 2, title: "Dave Defender", position: "D" },
    { id: 3, title: "Sam Sweeper", position: "D" },
    { id: 4, title: "Matt Midfielder", position: "M" },
    { id: 5, title: "William Winger", position: "M" },
    { id: 6, title: "Fillipe Forward", position: "F" }
  ],
   
  all: function() { return this.players },
  
  get: function(id) {
    const isPlayer = p => p.id === id
    return this.players.find(isPlayer)
  }
}
// The FullRoster iterates over all of the players and creates
// a link to their profile page.
const FullRoster = () => (
  <div>
    <ul>
      {
        PlayerAPI.all().map(p => (
          <li key={p.id}>
            <Link to={`/PortfolioLink/${p.id}`}>{p.title}</Link>
          </li>
        ))
      }
    </ul>
  </div>
)

// The Player looks up the player using the id parsed from
// the URL's pathname. If no player is found with the given
// id, then a "player not found" message is displayed.
const Player = (props) => {
  const player = PlayerAPI.get(
    parseInt(props.match.params.id, 10)
  )
  if (!player) {
    return <div>     <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
    Sorry, but the player was not found</div>
  }
    return (
      <div>
            <section className="overview-block-ptb iq-over-black-70 jarallax iq-breadcrumb3 text-left iq-font-white" style={ PORTFOLIOStyle }  >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-12">
                        <div className="iq-mb-0">
                            <h2 className="iq-font-white iq-tw-6">Our News</h2>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>    

        <section class="  iq-hide awesome-box4">
                 
                 <div class="container">
                     <div class="row">
                         <div class="col-lg-4 col-sm-12 iq-mtb-15 phone-box iq-hide text-center">
                             <img alt="Oilex" src={BackgroundPORTFOLIO} class="img-fluid wow fadeInUp" data-wow-duration="1s" />
                              </div>
                             <div class="col-lg-8 col-sm-12 iq-mtb-15">
                                 <h2 class="small-title white iq-tw-6 iq-font-bkack">
                                 {player.title}
                                 </h2>
                                 
                                 
                                  
                             </div>
                         </div>
                     </div>
         </section>

         
        <Link to='/News'>Back</Link>
      </div>
    )
  }
  const Roster = () => (
    <Switch>
      <Route exact path='/PortfolioLink' component={FullRoster}/>
      <Route path='/PortfolioLink/:id' component={Player}/>
    </Switch>
  )
  
export default PortfolioLink;

 
