import React, { Component } from 'react';
import Background from '../../images/Oilex/CONTACTS_US/CONTACTS.jpeg';
import {translatable} from "react-multilingual";
import axios from 'axios';
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
//import Example from './ContactsOrg';
 

let ContactStyle = {
    background_position: "center center",
    background_repeat: "no-repeat", 
    background_size: "cover",
    backgroundImage: `url(${Background})`
  };
 
  let tt = {
    color: "#666666"
  };
  
 

 
 class  Contacts extends Component {
  
    state = {
        name :'',
        phone :'',
        comment :'',
        email :'',
    };

    
    handleChange = event => {
        this.setState({
            [event.target.id] : event.target.value

        })
        
    }

    handelSubmit = event => {
        event.preventDefault();
        NotificationManager.success( 'Success message',  this.state.name);

        this.setState({
            name :'',
            phone :'',
            comment :'',
            email :'',
        })
        
        const Contacts = {
            name: this.state.name,
            phone: this.state.phone,
            comment: this.state.comment,
            email: this.state.email,
             
        };
        console.log(Contacts);
        axios.post('http://api.oilex.co/public/api/feedback/store',{name: Contacts.name , phone: Contacts.phone,comment: Contacts.comment,email:Contacts.email})
           .then(res => {
            console.log("ddd");
            console.log(res);
            console.log("ddd");
            console.log(res.data);
            
            
        })
        .catch(error => {
            console.log(error.res)
        });
        
     };

    componentDidMount(){
        document.title = "Contacts Us"
        
      }
  render() { 
    let {aboutTitle,CONTACTS,CONTACT1,CONTACT2,CONTACT3,CONTACT4,CONTACT5,CONTACT6,CONTACT7,h15,h16,h18,SliderTitle,small_title} = this.props;
    return ( 
      <div  >
        
        <section className="overview-block-ptb iq-over-black-70 jarallax iq-breadcrumb3 text-left iq-font-white" style={ ContactStyle } >
            <div className="container" style={aboutTitle} >
                <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-12">
                        <div className="iq-mb-0">
                            <h2 className="iq-font-white iq-tw-6">{CONTACTS}</h2>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
         
        <div className="main-content iq-contact2">
           
            <div className="iq-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3442.5565214039357!2d30.5332186872811!3d30.363551205696105!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa42fee9a3934fa6b!2sOilex!5e0!3m2!1sen!2seg!4v1542552175687">
                </iframe>
            </div>


            
            <section className="iq-our-touch overview-block-pb">
                <div className="container"  style={aboutTitle}>
                    <div className="iq-get-in iq-pall-40 white-bg">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 iq-mtb-15">
                                <h4 className="heading-left iq-tw-6 iq-pb-20 iq-mb-20"  style={small_title} >{CONTACT1}</h4>
                                <form   onSubmit={this.handelSubmit}>
                                    <div className="contact-form">
                                   
                                        <div className="section-field iq-mt-10">
                                            <input className="require" id="name" name="name" type="text" placeholder={CONTACT2}  
                                            onChange={this.handleChange} value={this.state.name} required/>
                                        </div>
                                        <div className="section-field iq-mt-10">
                                            <input className="require" id="email" name="email" type="email" placeholder={CONTACT3} onChange={this.handleChange} required value={this.state.email} />
                                        </div>
                                        <div className="section-field iq-mt-10">
                                            <input className="require" id="phone"  name="phone" type="text" placeholder={CONTACT4}  onChange={this.handleChange} required value={this.state.phone} />
                                        </div>
                                        <div className="section-field textarea iq-mt-10">
                                            <textarea id="comment" name="comment" className="input-message require" placeholder={CONTACT5} rows="5"  
                                            onChange={this.handleChange} required value={this.state.comment} />
                                        </div>
                                         
                                      
                                        <button id="submit"  onClick={this.handelSubmit.this} name="submit" type="submit" value="Send" className="button pull-right iq-mt-20">{CONTACT6}</button>
                                        <p role="alert"></p>
                                        <NotificationContainer/> 
                                    </div>
                                    
                                </form>
                                        
                                     
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 iq-mtb-15">
                                <div className="contact-info iq-pall-60 iq-pt-0">
                                    <h4 className="heading-left iq-tw-6 iq-mb-20 iq-pb-20" style={small_title} >{CONTACTS}</h4>
                                    <div className="iq-contact-box media">
                                        <div className="iq-icon left">
                                            <i aria-hidden="true" className="ion-ios-location-outline"></i>
                                        </div>
                                        <div className="contact-box right media-body">
                                            <h5 className="iq-tw-6">{CONTACT7}</h5>
                                            <p>{h18}</p>
                                        </div>
                                    </div>
                                    <div className=".iq-contact-box right media iq-mt-40">
                                        <div className="iq-icon left">
                                            <i aria-hidden="true" className="ion-ios-telephone-outline"></i>
                                        </div>
                                        <div className="contact-box right media-body" style={SliderTitle}>
                                            <h5 className="iq-tw-6">{h16}</h5>
                                            <span className="lead iq-tw-5" type="tel">
                                            
                                            <a href="tel:+20 101 510 4040" style={tt}>+02 28132222</a><br/>
                                            <a href="tel:+20 101 510 4040" style={tt}>+02 28132255</a><br/>
                                            <a href="tel:+20 101 510 4040" style={tt}>+02 28132233</a><br/>
                                            <a href="tel:+20 101 510 4040" style={tt}>+02 0228132244</a>  
                                            </span>
                                            <div className="iq-mb-0">Sun-Thu 9:00am - 5:00pm</div>
                                        </div>
                                    </div>
                                    <br/> <br/>
                                    <div className=".iq-contact-box right media iq-mt-40">
                                        <div className="iq-icon left">
                                            <i aria-hidden="true" className="ion-ios-email-outline"></i>
                                        </div>
                                       
                                        <div className="contact-box right media-body">
                                            <h5 className="iq-tw-6">{h15}</h5>
                                            <span className="lead iq-tw-5">info@oilex.co</span>
                                             
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        
    </div>
        
      </div>
      
    );
  }
}

 
const mapTranslationsToProps = ({aboutTitle,CONTACTS,CONTACT1,CONTACT2,CONTACT3,CONTACT4,CONTACT5,CONTACT6,CONTACT7,h15,h16,h18,SliderTitle,small_title}) => ({aboutTitle,CONTACTS,CONTACT1,CONTACT2,CONTACT3,CONTACT4,CONTACT5,CONTACT6,CONTACT7,h15,h16,h18,SliderTitle,small_title});

export default translatable(mapTranslationsToProps)(Contacts);
