import React, { Component } from 'react';
import {translatable} from "react-multilingual";

class FooterOil extends Component {
  render() {
    let {appNav,footerLang,app,Footer1,Footer2} = this.props;
    return (
      
        <footer className="iq-footer2 iq-over-black-80 iq-bg jarallax"  >
            
        <div className="footer-bottom iq-ptb-20">
            <div className="container">
                <div className="row" style={app}>
                    <div className="col-sm-6" style={footerLang}>
                        <div className="iq-copyright iq-mt-10 iq-font-white">
                           {Footer1}
                            <span id="copyright"> </span>
                                   
                            <a href="/"> Oilex</a> {Footer2} <a href="http://innovitics.com/">Innovitics Inc.</a>
                        </div>
                    </div>
                    <div className="col-sm-6 iq-re-4-mt30">
                        <ul className="iq-media-blog" style={appNav}>
                            <li><a href="https://www.instagram.com/oilex2001/?hl=en" target="_blank">
                            <i className="fa fa-instagram"></i></a></li>
                            <li><a href="https://twitter.com/Oilex2001" target="_blank">
                            <i className="fa fa-twitter"></i></a></li>
                            <li><a href="https://www.facebook.com/OilexMultiSeeds/?ref=br_rs" target="_blank">
                            <i className="fa fa-facebook"></i></a></li>
                             
                        </ul>
                    </div>
                </div>
           </div> 
        </div>
    </footer>
     
     
     
     
     
        
      
    );
  }
}

 
const mapTranslationsToProps = ({ appNav,footerLang,app,Footer1,Footer2}) => ({ appNav,footerLang,app,Footer1,Footer2 });

export default translatable(mapTranslationsToProps)(FooterOil);

