import React, { Component } from 'react';
import Logo from '../images/Oilex/logocolored.png';
import { NavLink } from 'react-router-dom'; 
import DashboardContainer from "./routes/example/app/containers/dashboard/DashboardContainer";
import {translatable} from "react-multilingual";




let sectionStyle = {
    top: "0",
    left: "0",
    background: "#006c64",
    color: "#ffffff",
    content: "",
    height: "100%",
    width: "100%",
    z_index: "-1",
    border_radius: "3px",
  };
const NavBarLink = ({to, label}) => (
    <li   >
       <NavLink    to={to} > {label} </NavLink>
     </li>
)
  
class Navbar extends Component {
  render() {
    let {ABOUT , New, Home, PRODUCTS, PORTFOLIO, CONTACTS ,applogo,appNav,ApplyJob} = this.props;
    return (
        <header className="header-01 white re-none">
         <nav id="menu-1" className="mega-menu">
         <section className="menu-list-items">
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-sm-12">
                          
                           <ul className="menu-logo" style={applogo}>
                              <li>
                                  <a href="Home">
                                      <img id="logo_dark" src={Logo} alt="Oilex" />
                                      
                                  </a>
                              </li>
                          </ul>
                           

                            <div>
                            <DashboardContainer/>  
                            </div>
                                
                          <ul  style={appNav} className="menu-links">
                          <li    >
                          <a href="/" >{Home}</a> 
                          
                          </li>
                             
                          <NavBarLink to="/ABOUT_US" label={ABOUT} /> 
                          <NavBarLink to="/PRODUCTS" label={PRODUCTS} /> 
                          <NavBarLink to="/PORTFOLIO" label={PORTFOLIO} />
                          
                          <NavBarLink to="/News" label={New} /> 
                          <NavBarLink to="/CONTACTS_US" label={CONTACTS} /> 
                          <NavBarLink to="/Apply_Job" label={ApplyJob} /> 
                           
                          <li  >
                           
                            
                             
                          </li>
                          
                      </ul>

                      
                      </div>
                  </div>
              </div>
          </section>
            
        </nav>
    </header>
       
 
  

 
       
         
    );
  }
}

 
const mapTranslationsToProps = ({ABOUT,New,Home,PRODUCTS,PORTFOLIO,CONTACTS,applogo,appNav,ApplyJob}) => ({ABOUT,New,Home,PRODUCTS,PORTFOLIO,CONTACTS,applogo,appNav,ApplyJob });

export default translatable(mapTranslationsToProps)(Navbar);