import React, { Component } from 'react';
import Background from '../../images/Oilex/ABOUT_US/ABOUT1.jpg';
import BackgroundABOUT2 from '../../images/Oilex/ABOUT_US/ABOUT2.jpg';
import BackgroundABOUT3 from '../../images/Oilex/ABOUT_US/ABOUT3.jpg';
import BackgroundABOUT4 from '../../images/Oilex/ABOUT_US/ABOUT4.jpg'; 
import BackgroundTeam1 from '../../images/Oilex/ABOUT_US/Team/Team1.jpg';
import BackgroundTeam2 from '../../images/Oilex/ABOUT_US/Team/Team2.jpg';
import BackgroundTeam3 from '../../images/Oilex/ABOUT_US/Team/Team3.jpg'; 
import BackgroundTeam5 from '../../images/Oilex/ABOUT_US/Team/Team5.jpg';
import './pagination2';
import {translatable} from "react-multilingual";

let ABOUTStyle = {
      
    backgroundImage: `url(${Background})`,
    background_size: "cover",
  };

 
 


class About extends Component {
    componentDidMount(){
        document.title = "About Us"
      }
  render() {
    let {ABOUT,aboutTitle,app,about1,about2,about3,about4,about5,about6,about7} = this.props;
    return (
      <div  >
        
        <section className="overview-block-ptb iq-over-black-70 jarallax iq-breadcrumb3 text-left iq-font-white" style={ ABOUTStyle }  >
            <div className="container" style={aboutTitle}>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-12">
                        <div className="iq-mb-0">
                            <h2 className="iq-font-white iq-tw-6">
                            <p>
					{ABOUT}
				</p>
                </h2>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>     




         <section className="overview-block-ptb iq-hide">
                <div className="container" style={app} >
                    <div className="row">
                        <div className="col-lg-4 col-md-12 iq-mtb-15">
                            <div className="feature-aria">
                                <img className="img-fluid wow fadeInLeft" data-wow-duration="1s" src={BackgroundABOUT2} alt="Oilex" />
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-12 iq-mtb-15">
                                <div className="iq-feature7 wow fadeInRight" data-wow-duration="1s">
                                    <div className="feature-aria">
                                        <div className="feature-content">
                                            <h3 className="iq-font-dark iq-tw-6">{about1}</h3>
                                            {about2}
                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row iq-mt-10">
                            <div className="col-lg-8 col-md-12 iq-mtb-15">
                                <div className="iq-feature7 wow fadeInLeft" data-wow-duration="1.5s">
                                    <div className="feature-aria">
                                        <div className="feature-content">
                                            <h3 className="iq-font-dark iq-tw-6">{about3}</h3>
                                            {about4}
                                             
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 iq-mtb-15 iq-re-9-mt30 order-first order-lg-2">
                                <div className="feature-aria">
                                    <img className="img-fluid wow fadeInRight" data-wow-duration="1.5s" src={BackgroundABOUT3} alt="Oilex" />
                              </div>
                                </div>
                            </div>


                            <div className="col-lg-4 col-md-12 iq-mtb-15">
                            <div className="feature-aria">
                                <img className="img-fluid wow fadeInLeft" data-wow-duration="1s" src={BackgroundABOUT4} alt="Oilex" />
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-12 iq-mtb-15">
                                <div className="iq-feature7 wow fadeInRight" data-wow-duration="1s">
                                    <div className="feature-aria">
                                        <div className="feature-content">
                                            <h3 className="iq-font-dark iq-tw-6">{about5}</h3>
                                            {about6}
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                        
                       
                         
                        
            </section>

             

            



             <section className="overview-block-ptb">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12 col-md-12">
                    <div className="heading-title text-center">
                    <h2 className="title iq-tw-6"> {about7}</h2>
                     
                    </div>
                    </div>
                    </div>
 

                    <div className="row">
                    <div className="col-lg-4 col-md-6 iq-mtb-15"></div>
                    <div className="col-lg-4 col-md-6 iq-mtb-15">
                    <div className="iq-team6">
                    <div className="team-blog mb-30">
                    <div className="team-images"> 
                      <img className="img-fluid wd-full" src={BackgroundTeam1} alt="Oilex" /> 
                    </div>
                    <div className="team-description">
                    <div className="line"></div>
                    <h5 className="iq-tw-6 iq-mt-10"> 
                    Dr. Sherif Zeyada </h5>
                    <p>Chairman of the board
                     </p>
                     
                    </div>
                    </div>
                    </div>
                    
                    </div>


                      

                      <div className="col-lg-4 col-md-6 iq-mtb-15"></div>
                    <div className="col-lg-4 col-md-6 iq-mtb-15">
                    <div className="iq-team6">
                    <div className="team-blog mb-30">
                    <div className="team-images"> <img className="img-fluid wd-full"  src={BackgroundTeam2} alt="Oilex" /> 
                    </div>
                    <div className="team-description">
                    <div className="line"></div>
                    <h5 className="iq-tw-6 iq-mt-10"> 
                    Mr. Emad Zeyada </h5>
                    <p></p>
                    
                    </div>
                    </div>
                    </div>
                    </div>



                     
                    <div className="col-lg-4 col-md-6 iq-mtb-15">
                    <div className="iq-team6">
                    <div className="team-blog mb-30">
                    <div className="team-images"> 
                    <img className="img-fluid wd-full"  src={BackgroundTeam3} alt="Oilex" /> 
                      </div>
                    <div className="team-description">
                    <div className="line"></div>
                    <h5 className="iq-tw-6 iq-mt-10"> 
                    Dr. Ahmed Zeyada </h5>
                    <p></p>
                     
                    </div>
                    </div>
                    </div>
                    </div>

                     <div className="col-lg-4 col-md-6 iq-mtb-15">
                    <div className="iq-team6">
                    <div className="team-blog mb-30">
                    <div className="team-images"> <img className="img-fluid wd-full"  src={BackgroundTeam5} alt="Oilex" /> 
                    </div>
                    <div className="team-description">
                    <div className="line"></div>
                    <h5 className="iq-tw-6 iq-mt-10"> 
                    Mr. Mohamed El-Kheshen </h5>
                    <p></p>
                    
                    </div>
                    </div>
                    </div>
                    </div>


    
                    </div>
               </div>
            </section>



        
      </div>
    );
  }
}

const mapTranslationsToProps = ({ABOUT,aboutTitle,app,about1,about2,about3,about4,about5,about6,about7}) => ({ABOUT,aboutTitle,app,about1,about2,about3,about4,about5,about6,about7});

export default translatable(mapTranslationsToProps)(About);
 
