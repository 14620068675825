import React, { Component , ReactDOM } from 'react';
import Background from '../../images/Oilex/Oilex4.jpg';
import {translatable} from "react-multilingual";
//import axios from 'axios';
//import { BrowserRouter as Router, Switch, Route, Link , HashRouter } from "react-router-dom"; 
import TodoApp from './pagination2';
//import PortfolioLink from './PortfolioLink';
//import FullRoster from './PortfolioLink';
 

let sectionStyle1 = {
    background_position: "center center",
    background_repeat: "no-repeat", 
    background_size: "cover",
    backgroundImage: `url(${Background})`
  };

  
 
  
  
class News extends Component {
       
  render() {
    let {News,aboutTitle} = this.props;
    return (
        
      <div  >
    
    <div>
       
       
    </div>
  
        <section className="overview-block-ptb iq-over-black-70 jarallax iq-breadcrumb3 text-left iq-font-white" style={ sectionStyle1 } >
            <div id="app" className="container " style={aboutTitle} >
                <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-12">
                        <div className="iq-mb-0">
                            <h2 className="iq-font-white iq-tw-6">
                            {News}  </h2>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section>
         
   
<TodoApp />
                                
                                                  
                                
                               
                           
                        
                              
 
      </div>
      
    );
    
  }
  

}

 

 
  
 
const mapTranslationsToProps = ({News,aboutTitle}) => ({News,aboutTitle});

export default translatable(mapTranslationsToProps)(News);
