import React, { Component } from 'react';
import {translatable} from "react-multilingual";
import axios from 'axios';
import Background from '../../images/Oilex/PRODUCTS/Products1.jpg'; 
import BackgroundProducts2 from '../../images/Oilex/PRODUCTS/Products2.png';
 
let HomeSlider16 = {

    background_image: `url(${Background})`,
    background_position: "left center",
     
  }
   
let PRODUCTStyle = {
      
    backgroundImage: `url(${Background})`
  };
 
  
class Prodacts extends Component {
    state = {
        products: [], 
    }; 

    shouldComponentUpdate(nextProps)
    { 
      if (this.props !== nextProps) { 
               let {ProdactsApi} = nextProps;
               //console.log(ProdactsApi);
               axios.post(ProdactsApi) 
               .then(res => {
                //console.log(res)
                   this.setState({
                    products: res.data.result.data
                   })
                   this.forceUpdate()
           })
        }
        return false 
    }

    componentDidMount(){
        document.title = "Products";
         
        let {ProdactsApi} = this.props; 
        axios.post(ProdactsApi)
        .then(res => {
            console.log(res)
            this.setState({
                products: res.data.result.data 
                })
                this.forceUpdate()
        })


      }
  render() {
    let {OurProducts,aboutTitle,app,Product1} = this.props;
    return (
      <div  >
        
        <section className="overview-block-ptb iq-over-black-70 jarallax iq-breadcrumb3 text-left iq-font-white" style={ PRODUCTStyle }   >
            <div className="container" style={aboutTitle}>
                <div className="row align-items-center">
                    <div className="col-lg-6 col-sm-12">
                        <div className="iq-mb-0">
                            <h2 className="iq-font-white iq-tw-6">{OurProducts}</h2>
                        </div>
                    </div>
                    
                </div>
            </div>
        </section> 


        <section className="overview-block-pb iq-mt-60 iq-about3">
                 <div className="container" style={app} >
                     <div className="row">
                         <div className="col-lg-8 col-sm-12">
                         <br/> 
                             <h2 className="small-title iq-tw-6">
                             </h2>
                            <p>{Product1}</p>
                             
                              
                         </div>
                         <div className="col-lg-4 col-sm-12">
                             <div className="year-bg iq-pall-15 iq-bg jarallax" style={HomeSlider16} >
                              <img className="img-fluid" src={BackgroundProducts2} alt="Oilex" />
                             </div>
                             </div>
                             <div className="col-sm-12">
                             </div>
                         </div>
                     </div>
             </section>


        <section className="overview-block-ptb iq-hide">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="heading-title text-center">
                                <h2 className="title iq-tw-6">{Prodacts}</h2>
                                 
                            </div>
                        </div>
                    </div>
                    <div className="row" style={aboutTitle} >


                    {this.state.products.map(product =>
                        
                         
                        <div className="col-lg-3 col-md-6 iq-mt-20 " key={product.id}   >
                        <div className="ttb">
                            <div className="grey-bg iq-pall-20 wow fadeInUp" data-wow-duration="2s">
                                <h1 className="iq-tw-6 iq-font-green">0{product.id}.</h1>
                                <h5 className="iq-tw-6 iq-mt-10">{product.title}
                                </h5>
                                <p>{product.description}</p>
                            </div> 
                            </div>   
                         
                        </div>
                        
                    ) }


                    </div>
                </div>
            </section>         
        
      </div>
    );
  }
}

 
const mapTranslationsToProps = ({OurProducts,aboutTitle,app,ProdactsApi,Product1}) => ({OurProducts,aboutTitle,app,ProdactsApi,Product1});

export default translatable(mapTranslationsToProps)(Prodacts);
