import React, { Component } from 'react';




 
 
 
     
      

   
 
//import '../css/bootstrap.min.css'; 
//import '../css/font-awesome.min.css';
//import '../css/ionicons.min.css';
//import '../css/mega-menu/mega_menu.css';
//import '../css/owl-carousel/owl.carousel.css';
//import '../css/magnific-popup.css';
//import '../css/animate.css';
//import '../css/mediaelementplayer.min.css';
//import '../revolution/css/settings.css';
//import '../revolution/css/revolution.addon.particles.css';
//import '../css/shortcodes.css';
//import '../css/style.css';
//import '../css/shop.css';
//import '../css/responsive.css';
//import '../css/custom.css';

//<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/1.12.4/jquery.js"></script>
 
//import '../js/jquery.min.js';
//import '../revolution/js/jquery.revslider-slider.js';
//import '../revolution/js/jquery.themepunch.tools.min.js'; 
//import '../revolution/js/extensions/revolution.extension.slideanims.min.js';
//import '../revolution/js/extensions/revolution.extension.parallax.min.js';
//import '../revolution/js/jquery.themepunch.revolution.min.js';
//import '../revolution/js/extensions/revolution.extension.actions.min.js';
//import '../revolution/js/extensions/revolution.extension.carousel.min.js';
//import '../revolution/js/extensions/revolution.extension.kenburn.min.js';
//import '../revolution/js/extensions/revolution.extension.layeranimation.min.js';
//import '../revolution/js/extensions/revolution.extension.migration.min.js';
//import '../js/popper.min.js';
//import '../js/bootstrap.min.js';
//import '../js/mega-menu/mega_menu.js';
//import '../js/main.js';
//import '../js/custom.js';


 
     
     
      
     
     
     
     
     
     
     


class LikeHead extends Component {
  render() {
    return (
    <div>
      
    </div>
     

    );
  }
}

export default LikeHead; 